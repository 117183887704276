import * as types from "./types";
import { sleep } from "../../services/commonService";
import { getRequest } from "../../services/requestsService";
// @ts-ignore
export function setUserDetails(userDetails) {
  return {
    type: types.SET_USER_DETAILS,
    payload: { userDetails },
  };
}

export const getUserDetails = () => async (dispatch: any) => {
  dispatch({
    type: types.SET_USER_DETAILS,
    payload: { isUserDetailsLoading: true },
  });
  const payload = { isUserDetailsLoading: false, userDetails: null, groups: null };
  try {
    const res = await getRequest("/user");
    payload.userDetails = res.data.user;
    payload.groups = res.data.groups;
  } catch (e) {
    // Handle any errors here
  }
  dispatch({
    type: types.SET_USER_DETAILS,
    payload: { ...payload },
  });

  return payload.userDetails;
};
