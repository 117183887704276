import axios from "axios";
import { getSessionToken } from "@descope/react-sdk";

const COOKIE_NAME_AUTH_TOKEN = "x-access-token";
const Axios = axios.create({
  // withCredentials: true,
  // crossdomain: true,
});

// axios.defaults.preflightContinue = true;

Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
Axios.interceptors.request.use((req) => {
  //  req.headers['Referer'] = window.location.origin
  try {
    const accessToken = getSessionToken();

    req.headers[COOKIE_NAME_AUTH_TOKEN] = accessToken;
    req.headers["Access-Control-Allow-Origin"] = window.location.origin;
  } catch (e) {
    console.log(e);
  }
  return req;
});

 const BASE_URL = process.env.REACT_APP_APP_BASE_URL;

//"https://us-central1-trustavolunteer.cloudfunctions.net/";
// "http://127.0.0.1:5001/trustavolunteer/us-central1/verifiedUsers" ||
  // "https://us-central1-trustavolunteer.cloudfunctions.net/verifiedUsers";

// Axios.interceptors.response.use(
//   async (res) => {
//     try {
//       const authToken = res.headers[COOKIE_NAME_AUTH_TOKEN];
//       if (authToken) {
//         setAuthToken(authToken);
//       }
//     } catch (e) {
//       console.log(e);
//     }
//     return res;
//   },
//   (error) => {
//     if (!isDev()) {
//       Sentry.captureException(error);
//     }
//     return Promise.reject(error);
//   },
// );

export const deleteRequest = (relativeUrl: string, params = {}) => {
  const requestUrl = `${BASE_URL}${relativeUrl}`;
  return Axios.delete(requestUrl, {
    params,
    headers: {
      Accept: "application/json",
    },
  });
};

export const postRequest = (relativeUrl: string, params = {}) => {
  const requestUrl = `${BASE_URL}${relativeUrl}`;
  return Axios.post(requestUrl, params, {
    headers: {
      Accept: "application/json",
    },
  });
};

export const getRequest = (
  relativeUrl: string,
  params: Record<string, string> = {},
  config = {},
) => {
  const requestUrl = `${BASE_URL}${relativeUrl}`;
  return Axios.get(requestUrl, {
    params,
    withCredentials: false,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    ...config,
  });
};
