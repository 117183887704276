import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import AuthReducer from './reducers/Auth'
import GroupReducer from './reducers/Group';

export const store = configureStore({
  reducer: {
    // @ts-ignore
    AuthReducer,
    // @ts-ignore
    GroupReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;