
import React from "react";

interface IProps {
  url?: string;
  logoWrapperStyle?: object;
  logoStyle?: object;
  logoClassName?: string;
}

const Logo = React.memo<IProps>((props) => {
  const { url } = props;

  let style = { height: 65, marginBottom: 0 };
  if (props.logoStyle) {
    style = {
      ...style,
      ...props.logoStyle,
    };
  }
  let logoWrapperStyle = {
    display: "flex",
    margin: "0 0 20px",
    justifyContent: "center",
  }
  if (props.logoWrapperStyle) {
    logoWrapperStyle = {
        ...logoWrapperStyle,
        ...props.logoWrapperStyle,
      };
  }
  return (
    <div
      style={logoWrapperStyle}
    >
      
    </div>
  );
});

export default Logo;
