import * as types from "./types";

interface GroupData {
  group: unknown;
  isGroupsLoading: boolean;
  groups: [];
  members: [];
}

const initialState: GroupData = {
  group: undefined,
  isGroupsLoading: false,
  groups: [],
  members: [],
};

type ActionType = {
  type: string;
  payload: unknown;
};

export default function GroupReducer(
  state: GroupData = initialState,
  action: ActionType
): GroupData {
  const { type, payload } = action;

  switch (type) {
    case types.SET_GROUPS:
    case types.SET_GROUP:
      return {
        ...state,
        // @ts-ignore
        ...payload, 
      };
    default:
      return state;
  }
}
