import { useCallback, useState } from "react";

export enum TFetchingState {
  NONE = 1,
  FETCHING,
  ERROR,
}

type TFetchingGist = {
  fetchingState: TFetchingState;
  errorMessage: string;
};

export function useFetchingState() {
  const [fetchingGist, setFetchingGist] = useState<TFetchingGist>({
    fetchingState: TFetchingState.NONE,
    errorMessage: "",
  });

  const signalNewFetch = useCallback(() => {
    setFetchingGist({
      fetchingState: TFetchingState.FETCHING,
      errorMessage: "",
    });
  }, []);

  const signalFetchDone = useCallback(() => {
    setFetchingGist({
      fetchingState: TFetchingState.NONE,
      errorMessage: "",
    });
  }, []);

  const signalFetchError = useCallback((errorMessage: string) => {
    setFetchingGist({
      fetchingState: TFetchingState.ERROR,
      errorMessage: errorMessage,
    });
  }, []);

  return {
    fetchingState: fetchingGist.fetchingState,
    fetchingError: fetchingGist.errorMessage,
    signalNewFetch,
    signalFetchError,
    signalFetchDone,
  };
}
