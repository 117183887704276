import moment from 'moment';

export const TABLE_COLUMNS = (onApprove: any, userGroups: [], onMakeAdmin: any) => {

    return [
        {
            key: 'group',
            dataIndex: 'group',
            title: 'Group',
        },
        {
            key: 'createdAt',
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (createdAt: any) => moment(createdAt).format('DD/MM/YYYY'),
            // @ts-ignore
            sorter: (a: any, b: any) => new Date(a.createdAt) - new Date(b.createdAt),
        },
        {
            key: 'phoneNumber',
            title: "Phone",
            dataIndex: 'phoneNumber'
        },
        {
            key: 'name',
            title: "Name",
            render: (user: any) => `${user.firstName} ${user.lastName}`,
        },
        {
            key: 'kycStatus',
            title: "Status",
            filters: [
                {
                    text: 'Verified',
                    value: 'SUCCESS',
                },
                {
                    text: 'Did not complete',
                    value: 'PENDING',
                },
                {
                    text: "Failed",
                    value: "FAILED"
                },
            ],
            filterSearch: true,
            onFilter: (value: string, record: any) => {
                return record?.verification?.kycStatus === value
            },
            render: (user: any) => {
                const KYC_MAP = {
                    'PENDING': "Did not complete",
                    'SUCCESS': 'Verified',
                    'MANUAL_REVIEW': "Manual check",
                    'FAILURE': "Failed"
                }

                // @ts-ignore
                return KYC_MAP[user.verification?.kycStatus] || "Unknown";
            }
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (user: any) => {
                let verifyComponent, adminComponent
                const groupsUserNotMemberIn = userGroups.filter(group => !group.admins.includes(user.id))
                if (!user.verification.verified)
                    verifyComponent = (
                        <div className="action-btn" onClick={() => onApprove(user.id)}>Approve</div>
                    )

                if (groupsUserNotMemberIn.length > 0) {
                    adminComponent = <div className="action-btn" onClick={e => onMakeAdmin(user.id)}>Make admin</div>
                }
                return <>
                    {verifyComponent || null}
                    <br />
                    {adminComponent || null}
                </>
            }
        }
    ];
}