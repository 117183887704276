import { useDescope, useSession } from "@descope/react-sdk";
import { Button, Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { deleteRequest } from "../../services/requestsService.ts";
import Logo from "../Logo.tsx";
import {
  TFetchingState,
  useFetchingState,
} from "../../services/fetchingHooks.ts";
import { getUserDetails } from "../../reducers/Auth/actions.ts";
import { Spin } from "antd";

interface IProps { }

export const ProtectedLayout = React.memo<IProps>((props) => {
  const { userDetails, isUserDetailsLoading } = useSelector(
    ({ AuthReducer }) => AuthReducer
  );

  const navigate = useNavigate();
  const { fetchingState, signalNewFetch, signalFetchDone, signalFetchError } =
    useFetchingState();
  const { logout } = useDescope();
  const dispatch = useDispatch();
  // Note : Quick solution to allow to delete account (and basically to force a new KYC process)
  //        in case the KYC failed
  const isLoadingDeleteRequest = fetchingState === TFetchingState.FETCHING;
  const requestToDeleteAfterFailedKyc = useCallback(async () => {
    signalNewFetch();
    try {
      await deleteRequest("/user");
      await logout();
      navigate("/login");
      signalFetchDone();
    } catch (e) {

    }
  }, [logout, navigate, signalFetchDone, signalFetchError, signalNewFetch]);

  const { isAuthenticated, isSessionLoading } = useSession();

  /**
   * Fetch the user data from our API if it passed Descope
   * If there is no user or user hasn't completed the kyc flow --> redirect to login
   */
  const fetchUserDetails = async () => {
    // @ts-ignore
    const newUserDetails = await dispatch(getUserDetails());
    if (!newUserDetails || !newUserDetails.completedKycAt) {
      navigate("/login");
    }
  };


  useEffect(() => {
    // If descope is not authenticated --> redirect to login
    if (!isSessionLoading && !isAuthenticated) {
      navigate("/login");
    } else if (
      !isSessionLoading &&
      isAuthenticated &&
      !isUserDetailsLoading &&
      !userDetails
    ) {
      fetchUserDetails();
    }
  }, [isAuthenticated, isSessionLoading, userDetails]);

  // Check outside of useEffect as well, so we don't render the protected page if we're logged out
  if (!isSessionLoading && !isAuthenticated) {
    return <div ></div>;
  }

  if (isSessionLoading || isUserDetailsLoading) {
    return (
      <div
        style={{ justifyContent: "center" }}
      >
        <Spin />
      </div>
    );
  }
  if (!userDetails?.verified && userDetails?.completedKycAt) {
    return (
      <Modal
        open={true}
      >
        <div style={{ padding: "20px" }}>
          <Logo />

          <div>
            זיהוי בתהליך..
          </div>
          <div>

          </div>
          <br />
          {userDetails.kycStatus !== "FAILED" && (
            <Button
              variant={"contained"}
              onClick={() => window.location.reload()}
              disabled={isLoadingDeleteRequest}
            >
              בדוק סטטוס
            </Button>
          )}
        </div>
      </Modal>
    );
  }

  if (!(userDetails?.permissions || []).includes('admin')) return null;

  if (userDetails?.verified && userDetails?.completedKycAt) {
    return (
      <div style={{ width: '100%', maxWidth: '100%',padding: '0 10px', background: '#b5c2ca' }}>
        
        <Outlet />
      </div>
    );
  } else {
    return <></>;
  }
});

export default ProtectedLayout;
