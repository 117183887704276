
import { Spin } from "antd";
import Logo from "../../components/Logo"; // Removed ".tsx" extension
interface Props {
    hideLogo: boolean | null;
    message?: string;
}

const Spinner: React.FC<Props> = ({ hideLogo , message}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: 'center',
        height: "100vh",
        padding: "35px 0",
      }}
    >
      {!hideLogo && <Logo />}
      <div style={{textAlign: 'center', width: '100%'}}>
        <Spin />
      </div>

        {message ? <p>{message}</p> : ''}
    </div>
  );
};

export default Spinner;
