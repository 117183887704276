import { useDescope } from "@descope/react-sdk";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Spinner from '../components/layouts/Spinner'
interface Props { }

const LogoutPage: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { logout } = useDescope();

  useEffect(() => {
    logout().then(() => { navigate('/login') })
  }, [])

  return <div>
    <Spinner hideLogo />
  </div>
};

export default LogoutPage;
