import { Descope, useSession } from "@descope/react-sdk";
import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router";
import { Button } from "antd";
import { BsCheckCircle } from "@react-icons/all-files/bs/BsCheckCircle";
import {
  AUTH_STATE_SUCCESS,
  AUTH_STATE_LOGIN,
  AUTH_STATE_INITIAL,
  AUTH_STATE_KYC,
} from "./constants";
import Logo from "../../components/Logo.tsx";
import { postRequest } from "../../services/requestsService.ts";
import Spinner from "../../components/layouts/Spinner.tsx";
import { useSelector } from "react-redux";
import LandingPage from "./Components/LandingPage/index.tsx";

interface Props { }

const LoginPage: React.FC<Props> = (props) => {
  const routerParams = useParams();
  const [step, setStep] = useState(AUTH_STATE_INITIAL);
  const navigate = useNavigate();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { groupInviteId } = routerParams;

  const completeLocalAuth = async () => {
    const res = await postRequest("/kyc/generate", {
      // @ts-ignore
      groupInviteId: groupInviteId || null,
    });


    const { redirectUrl, completedKycAt, isNewUser } = res.data;

    window.location.href = "/"
  };

  return (
    <div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div width="100%">
            {![AUTH_STATE_LOGIN, AUTH_STATE_INITIAL].includes(step) && <Logo />}
            {/* @ts-ignore */}
            {step === AUTH_STATE_INITIAL && (
              <LandingPage setStep={setStep} selectedGroup={selectedGroup} loading={loading} />
            )}
            {step === AUTH_STATE_KYC && <Spinner hideLogo />}
            {step === AUTH_STATE_SUCCESS && (
              <div>
                <BsCheckCircle style={{ fontSize: "65px", color: "#3f8fd8" }} />
                <div
                  style={{
                    color: "#333",
                    fontSize: "18px",
                    paddingTop: "25px",
                  }}
                >
                  {t("auth_kyc_success")}
                </div>
              </div>
            )}
            {step === AUTH_STATE_LOGIN && (
              <div dir="ltr">
                <Descope
                  flowId="sign-up-or-in"
                  theme="light"
                  onSuccess={(user) => {
                    completeLocalAuth();
                    setStep(AUTH_STATE_KYC);
                  }}
                  onError={(err) => {
                    console.log("Error!", err);
                  }}
                />
                <div style={{ color: "#808080", direction: "rtl" }}>
                </div>
              </div>
            )}
          </div>
          {/* FOOTER BUTTONS */}
          <div style={{ width: "100%" }}>
            {step === AUTH_STATE_INITIAL && <></>}
            {step === AUTH_STATE_SUCCESS && (
              <Button
                onClick={() => navigate("/")}
                color="primary"
                size="large"
              >
                מעבר לאיזור האישי
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
