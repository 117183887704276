import { Button, Card, Form, Input, Modal, Select, Table } from "antd"
import "./index.scss"
import { useEffect, useState } from "react"
import { postRequest } from "../../services/requestsService";
import { TABLE_COLUMNS } from "./constants";
import { useSelector } from "react-redux";
import parsePhoneNumber from 'libphonenumber-js'


function fixPhoneNumber(number: string) {
    if (!number) return number;
    if (number.length === 10 && number.startsWith('0')) return `+972${number.slice(1)}`

    const numberObj = parsePhoneNumber(number);
    if (!numberObj) return number;

    return numberObj.number;
}



export default (props: any) => {
    const [params, setParams] = useState({
        name: '',
        number: ''
    })
    const [updateUserPermissionsId, setUpdateUserPermissionId] = useState()
    const { groups } = useSelector(
        ({ AuthReducer }) => AuthReducer
    );
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [userPermissions, setUserPermissions] = useState({
        groupId: "",
        userId: ""
    })

    useEffect(() => {
        onSearchSubmit();
    }, [])

    const onChange = (key: string, value: string) => {
        setParams({ ...params, [key]: value })
    }

    const onSearchSubmit = async () => {
        setLoading(true)
        const res = await postRequest('/backoffice/search', params)
        setResults(res.data?.results || [])
        setLoading(false)
    }
    const onApprove = async (requestedUserId: string) => {
        await postRequest('/backoffice/verify-user', { requestedUserId })
        onSearchSubmit();
    }
    const fixPhone = (e: any) => {
        if (!e.target.value) return;
        const currentNumber = e.target.value
        onChange('number', fixPhoneNumber(currentNumber))
    }
    const onMakeAdmin = (userId: string) => {
        setUserPermissions({ userId, groupId: "" })
    }
    const onMakeAdminSubmit = async () => {
        if (!userPermissions.groupId || !userPermissions.userId) return null;
        await postRequest('/backoffice/set-user-permissions', userPermissions)
        onSearchSubmit()
    }
    return (
        <div className="user-search">
            {/* SEARCH */}
            <Card className="search-card">
                <Form layout="vertical">
                    <Form.Item label="Phone number">
                        <Input
                            allowClear
                            placeholder="+972506123345" onChange={e => onChange('number', e.target.value)} onBlur={fixPhone} value={params.number}

                        />
                    </Form.Item>
                    <Form.Item label="Name">
                        <Input
                            allowClear
                            placeholder="John Doe" onChange={e => onChange('name', e.target.value)} value={params.name}

                        />
                    </Form.Item>
                    <Button loading={loading} onClick={onSearchSubmit} >Search</Button>
                </Form>
            </Card>

            <Card className="search-results">
                <h2>{`Results: ${results.length}`}</h2>
                <Table style={{ width: 'auto' }} pagination={{ pageSize: 100 }} dataSource={results} columns={TABLE_COLUMNS(onApprove, groups, onMakeAdmin)} loading={loading} />
            </Card>
            {/* @ts-ignore */}
            <Modal open={!!userPermissions.userId} onCancel={() => setUserPermissions({ groupId: "", userId: "" })} onOk={onMakeAdminSubmit}
            >
                <Select style={{ width: 300 }} options={groups.map(group => ({ label: group.name, value: group.id }))}
                    onSelect={newVal => setUserPermissions({ ...userPermissions, groupId: newVal })}
                />
            </Modal>
        </div>
    )
}