import React, { useMemo, useState } from "react";

import { useLocation } from "react-router";
import { AiOutlineMenu } from '@react-icons/all-files/ai/AiOutlineMenu'
import { Drawer } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.scss";

interface IProps { }

type THeaderLinkPayload = {
  text: string;
  linkTo: string;
  isActive: boolean;
  queryParamsToKeep: string[];
};

export const Header: React.FunctionComponent<IProps> = React.memo<IProps>(
  () => {
    const [open, setOpen] = useState(false)
    const { userDetails } = useSelector(
      ({ AuthReducer }) => AuthReducer
    );
    const location = useLocation();

   
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setOpen(true)
    };

    const HEADER_LINKS = useMemo<THeaderLinkPayload[]>(() => {
      return [
        {
          text: 'Home',
          linkTo: "/",
          isActive: location.pathname === "/",
          queryParamsToKeep: [],
        },
        {
          text: "Logout",
          linkTo: "/logout",
          isActive: location.pathname.includes("/logout"),
          queryParamsToKeep: [],
        },
      ];
    }, [location.pathname]);

    if (!userDetails) return null;
    return (
      <>
        <div className="header">
          <div className="left">
            <AiOutlineMenu onClick={handleOpenNavMenu} />
          </div>
          <div className="right">
            {`${userDetails.firstName || ""} ${userDetails.lastName || ""}`}
          </div>


        </div>
        <Drawer open={open} onClose={() => setOpen(false)} className="header-drawer" placement="left">
          {HEADER_LINKS.map(headerLink => (
            <Link to={headerLink.linkTo} onClick={() => setOpen(false)}>{headerLink.text}</Link>
          ))}
        </Drawer>
      </>
    );
  }
);
