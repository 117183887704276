
import React, { useState } from "react";
import {Button, Spin} from "antd";
import { AUTH_STATE_LOGIN } from "../../constants";

interface IProps {
  selectedGroup?: any;
  setStep?: (step: string) => void;
  loading: boolean;
}

export const LandingPage = React.memo<IProps>(
  ({ selectedGroup, setStep, loading }) => {

    // @ts-ignore
    if (loading) return <Spin />;
    return (
      <div className="landing-page">
        {/* HERO SECTION */}
        {/* @ts-ignore */}
        <Button onClick={() => setStep(AUTH_STATE_LOGIN)}>Login</Button>

      </div>
    );
  }
);

export default LandingPage;
