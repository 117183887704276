import UserSearch from './pages/UserSearch';
import { Provider } from 'react-redux';
import { store } from './store.ts'
import { Route, Routes } from 'react-router';
import ProtectedLayout from './components/layouts/ProtectedLayout.tsx';
import LoginPage from './pages/LoginPage/index.tsx';
import LogoutPage from './pages/LogoutPage.tsx';
import { Header } from './components/layouts/Header/Header/index.tsx';
import "./App.scss";


function App() {
  return (
    <Provider store={store}>
      <Header />
        <Routes>
          <Route element={<ProtectedLayout />} >
            <Route path="/" element={<UserSearch />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
        </Routes>
    </Provider>
  );
}

export default App;
