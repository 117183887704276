import * as types from './types';

interface UserData {
  userDetails: any;
  isUserDetailsLoading: boolean;
  groups: [],
}

const initialState: UserData = {
  userDetails: undefined,
  isUserDetailsLoading: false,
  groups: [],

};

type ActionType = {
  type: string;
  payload: any; 
};

export default function AuthReducer(state: UserData = initialState, action: ActionType): UserData {
  const { type, payload } = action;

  switch (type) {
    case types.SET_USER_DETAILS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
